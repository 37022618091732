<template>
  <div class="appointmentList-view">
    <el-form v-model="form" :inline="true" label-width="80px">
      <el-row>
        <el-form-item label="表单标题">
          <el-input
            type="text"
            placeholder="请输入内容"
            v-model="form.title"
            style="width: 400px"
            maxlength="10"
          ></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="表单内容">
          <el-input
            type="text"
            placeholder="请输入内容"
            v-model="form.content"
            maxlength="50"
            style="width: 400px"
            show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-row>

      <div v-for="(item, index) in form.detailRequests" :key="index">
        <el-row v-if="item.formType == 'SINGLE'" class="commentForm">
          <el-row>
            <el-form-item label="标题">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="item.title"
                style="width: 400px"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-switch
                v-model="item.required"
                active-text="开"
                inactive-text="关"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-top" @click="upTop(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-bottom" @click="downBottom(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteCompoents(form.detailRequests, index)"
              ></i>
            </el-form-item>
          </el-row>
          <el-row
            v-for="(children, chIndex) in item.childrenDetails"
            :key="chIndex"
          >
            <el-form-item label="内容">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="children.title"
                style="width: 400px"
                maxlength="20"
              ></el-input>
              <el-form-item>
                <i
                  class="el-icon-remove-outline"
                  @click="deleteSingle(item.childrenDetails, chIndex)"
                ></i>
              </el-form-item>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label=" ">
              <i
                class="el-icon-circle-plus-outline"
                @click="addSingle(item.childrenDetails)"
                >添加单选</i
              >
            </el-form-item>
          </el-row>
        </el-row>

        <el-row v-else-if="item.formType == 'MULTIPLE'" class="commentForm">
          <el-row>
            <el-form-item label="标题">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="item.title"
                style="width: 400px"
                maxlength="10"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-switch
                v-model="item.required"
                active-text="开"
                inactive-text="关"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-top" @click="upTop(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-bottom" @click="downBottom(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteCompoents(form.detailRequests, index)"
              ></i>
            </el-form-item>
          </el-row>

          <el-row
            v-for="(children, chIndex) in item.childrenDetails"
            :key="chIndex"
          >
            <el-form-item label="内容">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="children.title"
                style="width: 400px"
                maxlength="20"
              ></el-input>
              <el-form-item>
                <i
                  class="el-icon-remove-outline"
                  @click="deleteMultiple(item.childrenDetails, chIndex)"
                ></i>
              </el-form-item>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label=" ">
              <i
                class="el-icon-circle-plus-outline"
                @click="addMultiple(item.childrenDetails)"
                >添加多选</i
              >
            </el-form-item>
          </el-row>
        </el-row>

        <el-row v-else-if="item.formType == 'DROP_DOWN'" class="commentForm">
          <el-row>
            <el-form-item label="标题">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="item.title"
                style="width: 400px"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-switch
                v-model="item.required"
                active-text="开"
                inactive-text="关"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-top" @click="upTop(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-bottom" @click="downBottom(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteCompoents(form.detailRequests, index)"
              ></i>
            </el-form-item>
          </el-row>

          <el-row
            v-for="(children, chIndex) in item.childrenDetails"
            :key="chIndex"
          >
            <el-form-item label="内容">
              <i class="el-icon-caret-bottom"></i
              ><el-input
                type="text"
                placeholder="请输入内容"
                v-model="children.title"
                style="width: 386px"
                maxlength="20"
              ></el-input>
              <el-form-item>
                <i
                  class="el-icon-remove-outline"
                  @click="deleteDropDown(item.childrenDetails, chIndex)"
                ></i>
              </el-form-item>
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label=" ">
              <i
                class="el-icon-circle-plus-outline"
                @click="addDropDown(item.childrenDetails, 'DROP_DOWN')"
                >添加单个下拉</i
              >
            </el-form-item>
          </el-row>
        </el-row>

        <el-row v-else-if="item.formType == 'TEXTAREA'" class="commentForm">
          <el-row>
            <el-form-item label="标题">
              <el-input
                type="text"
                
                placeholder="请输入内容"
                v-model="item.title"
                style="width: 400px"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-switch
                v-model="item.required"
                active-text="开"
                inactive-text="关"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-top" @click="upTop(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-bottom" @click="downBottom(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteCompoents(form.detailRequests, index)"
              ></i>
            </el-form-item>
          </el-row>
          <el-row
            v-for="(children, chIndex) in item.childrenDetails"
            :key="chIndex"
          >
            <el-form-item label="内容">
              <el-input
                type="textarea"
                :disabled="true"
                :rows="2"
                placeholder="请输入文本"
                v-model="children.title"
                style="width: 400px"
                maxlength="500"
                show-word-limit
              >
              </el-input>
              <el-form-item>
                <i
                  class="el-icon-remove-outline"
                  @click="deleteDropDown(item.childrenDetails, chIndex)"
                ></i>
              </el-form-item>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label=" ">
              <i
                class="el-icon-circle-plus-outline"
                @click="addDropDown(item.childrenDetails, 'TEXTAREA')"
                >添加单个文本</i
              >
            </el-form-item>
          </el-row>
        </el-row>

        <el-row v-else-if="item.formType == 'SCOURE'" class="commentForm">
          <el-row>
            <el-form-item label="标题">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="item.title"
                style="width: 400px"
                maxlength="10"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-switch
                v-model="item.required"
                active-text="开"
                inactive-text="关"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-top" @click="upTop(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-bottom" @click="downBottom(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteCompoents(form.detailRequests, index)"
              ></i>
            </el-form-item>
          </el-row>
          <el-row
            v-for="(children, chIndex) in item.childrenDetails"
            :key="chIndex"
          >
            <el-form-item label="内容">
              <el-input
                type="text"
                placeholder="请输入文本"
                v-model="children.title"
                maxlength="10"
                style="width: 400px"
                show-word-limit
              >
              </el-input>
              <el-rate v-model="value2" :disabled="true" :colors="colors"> </el-rate>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteDropDown(item.childrenDetails, chIndex)"
              ></i>
            </el-form-item>
          </el-row>

          <el-row>
            <el-form-item label=" ">
              <i
                class="el-icon-circle-plus-outline"
                @click="addDropDown(item.childrenDetails, 'SCOURE')"
                >添加单个评分</i
              >
            </el-form-item>
          </el-row>
        </el-row>

        <el-row v-else-if="item.formType == 'IMAGE'" class="commentForm">
          <el-row>
            <el-form-item label="标题">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="item.title"
                style="width: 400px"
                maxlength="10"
              ></el-input>
            </el-form-item>

            <el-form-item label="是否必填">
              <el-switch
                v-model="item.required"
                active-text="开"
                inactive-text="关"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-top" @click="upTop(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-bottom" @click="downBottom(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteCompoents(form.detailRequests, index)"
              ></i>
            </el-form-item>
          </el-row>

          <el-form-item label="图片">
            <el-upload
              :action="actionURL"
              list-type="picture-card"
              :disabled="true"
              :auto-upload="false"
            >
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleDownload(file)"
                  >
                    <i class="el-icon-download"></i>
                  </span>
                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
              <div slot="tip" class="el-upload__tip">
                点击上传图片最大不可超过4MB
              </div>
            </el-upload>
          </el-form-item>

          <!--                    <el-row   v-for=" (children,chIndex) in item.childrenDetails">-->
          <!--                      <el-form-item label="图片数量">-->
          <!--                        <el-input-->
          <!--                                type="text"-->
          <!--                                placeholder="请输入图片上传张数(不超过9张)"-->
          <!--                                v-model="children.title"-->
          <!--                                style="width: 400px"-->
          <!--                                oninput ="value=value.match(/^\d*(\.?\d{0,2})/g)[0]"-->
          <!--                                max="9"-->
          <!--                                min="1"-->
          <!--                                maxlength="1"-->
          <!--                        ></el-input>-->
          <!--                      </el-form-item>-->
          <!--                    </el-row>-->
        </el-row>

        <el-row v-else-if="item.formType == 'GPS'" class="commentForm">
          <el-row>
            <el-form-item label="标题">
              <el-input
                type="text"
                placeholder="地址定位"
                v-model="item.title"
                style="width: 400px"
                maxlength="10"
              ></el-input>
            </el-form-item>

            <el-form-item label="是否必填">
              <el-switch
                v-model="item.required"
                active-text="开"
                inactive-text="关"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-top" @click="upTop(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-bottom" @click="downBottom(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteCompoents(form.detailRequests, index)"
              ></i>
            </el-form-item>
          </el-row>
          <el-row
            v-for="(children, chIndex) in item.childrenDetails"
            :key="chIndex"
          >
            <el-form-item label=" ">
              <el-input
                type="text"
                placeholder="点击获取位置"
                style="width: 400px"
                :disabled="true"
                v-model="children.title"
              ></el-input>
            </el-form-item>
          </el-row>
        </el-row>

        <el-row v-else-if="item.formType == 'USERINFO'" class="commentForm">
          <el-row>
            <el-form-item label="标题">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="item.title"
                style="width: 400px"
                maxlength="10"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-switch
                v-model="item.required"
                active-text="开"
                inactive-text="关"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-top" @click="upTop(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-bottom" @click="downBottom(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteCompoents(form.detailRequests, index)"
              ></i>
            </el-form-item>
          </el-row>
          <el-row
            v-for="(children, chIndex) in item.childrenDetails"
            :key="chIndex"
          >
            <el-row>
              <el-form-item label="标题">
                <el-input
                  type="text"
                  placeholder="请输入标题"
                  v-model="children.title"
                  maxlength="10"
                  style="width: 400px"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-row>
            <el-form-item label=" ">
              <el-input
                type="text"
                placeholder="请输入内容"
                :disabled="true"
                v-model="children.title"
                maxlength="200"
                style="width: 400px"
                show-word-limit
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteDropDown(item.childrenDetails, chIndex)"
              ></i>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label=" ">
              <i
                class="el-icon-circle-plus-outline"
                @click="addDropDown(item.childrenDetails, 'USERINFO')"
                >添加单个标题内容</i
              >
            </el-form-item>
          </el-row>
        </el-row>

        <el-row v-else-if="item.formType == 'DATEPICKER'" class="commentForm">
          <el-row>
            <el-form-item label="日期">
              <el-input
                type="text"
                placeholder="请输入内容"
                v-model="item.title"
                style="width: 400px"
                maxlength="10"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否必填">
              <el-switch
                v-model="item.required"
                active-text="开"
                inactive-text="关"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-top" @click="upTop(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i class="el-icon-bottom" @click="downBottom(item, index)"></i>
            </el-form-item>
            <el-form-item>
              <i
                class="el-icon-remove-outline"
                @click="deleteCompoents(form.detailRequests, index)"
              ></i>
            </el-form-item>
          </el-row>
          <el-row
            v-for="(children, chIndex) in item.childrenDetails"
            :key="chIndex"
          >
            <el-form-item label="内容">
              <el-date-picker
                v-model="children.title"
                type="daterange"
                style="width: 400px"
                range-separator="至"
                :disabled="true"
                start-placeholder="选择开始日期"
                end-placeholder="选择结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-row>
        </el-row>

        <div v-else-if="item.formType == ''"></div>
      </div>
      <el-row style="margin-top: 10px">
        <el-button
          type="primary"
          icon="el-icon-circle-plus"
          style="background-color: #0D6DFF; color: white"
          @click="dialogVisible = true"
          >添加</el-button
        >
      </el-row>
      <el-row align="right" style="width: 800px; text-align: right">
        <el-button
          style="background-color: #0D6DFF; color: white"
          @click="saveForm"
          >发布</el-button
        >
      </el-row>
    </el-form>
    <el-dialog
      title="选择类型"
      :visible.sync="dialogVisible"
      width="30%"
      :append-to-body="true"
      :before-close="handleClose"
    >
      <el-checkbox-group v-model="checkList">
        <!--                表单类型 如单选 多选 下拉 文本框 评分 图片上传 地址定位 个人信息 日期组合选择:'ALL','SINGLE','MULTIPLE','DROP_DOWN',
      'TEXTAREA','SCOURE','IMAGE','GPS','USERINFO','DATEPICKER'-->
        <el-row>
          <el-checkbox label="SINGLE">单选项</el-checkbox>
        </el-row>
        <el-row>
          <el-checkbox label="MULTIPLE">多选项</el-checkbox>
        </el-row>
        <el-row>
          <el-checkbox label="DROP_DOWN">下拉选项</el-checkbox>
        </el-row>
        <el-row>
          <el-checkbox label="TEXTAREA">文本框</el-checkbox>
        </el-row>
        <el-row>
          <el-checkbox label="SCOURE">评分</el-checkbox>
        </el-row>
        <el-row>
          <el-checkbox label="IMAGE">图片上传</el-checkbox>
        </el-row>
        <el-row>
          <el-checkbox label="GPS">地址定位</el-checkbox>
        </el-row>
        <el-row>
          <el-checkbox label="USERINFO">个人信息</el-checkbox>
        </el-row>
        <el-row>
          <el-checkbox label="DATEPICKER">日期选择</el-checkbox>
        </el-row>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="selectEnter"
          style="background-color: #0D6DFF; color: white"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryAdvancedFormById,
  addAdvancedForm,
  updateAdvancedForm,
} from "@/api/companyManage.js";
import { getDataTimeSec } from "@/utils";

export default {
  name: "appointmentList",
  data() {
    return {
      actionURL: this.$store.state.ossUrl,
      dialogVisible: false,
      text: "",
      radio: "",
      value: "",
      value1: "",
      value2: "",
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      options: [],
      checkList: [],
      disabled: true,
      type: "",
      form: {
        title: "",
        content: "",
        detailRequests: [
          {
            formType: "SINGLE",
            title: "",
            required: true,
            childrenDetails: [
              { formType: "SINGLE", title: "", content: "" },
              { formType: "SINGLE", title: "", content: "" },
            ],
          },
          // {formType:'MULTIPLE',title:'多选一',childrenDetails:[{formType:'MULTIPLE',title:'多选一'},{formType:'MULTIPLE',title:'多选2'}]},
          // {formType:'DROP_DOWN',title:'DROP_DOWN',childrenDetails:[{formType:'DROP_DOWN',title:'DROP_DOWN一'},{formType:'DROP_DOWN',title:'DROP_DOWN2'}]},
          // {formType:'TEXTAREA',title:'TEXTAREA',childrenDetails:[{formType:'TEXTAREA',title:'TEXTAREA1'},{formType:'TEXTAREA',title:'TEXTAREA2'}]},
          // {formType:'SCOURE',title:'SCOURE',childrenDetails:[{formType:'SCOURE',title:'SCOURE1'},{formType:'SCOURE',title:'SCOURE2'}]},
          // {formType:'IMAGE',title:'IMAGE',childrenDetails:[{formType:'IMAGE',title:'IMAGE1'}]},
          // {formType:'GPS',title:'GPS',childrenDetails:[{formType:'GPS',title:'GPS1'}]},
          // {formType:'USERINFO',title:'USERINFO',childrenDetails:[{formType:'USERINFO',title:'USERINFO1'},{formType:'USERINFO',title:'USERINFO2'}]},
          // {formType:'DATEPICKER',title:'DATEPICKER',childrenDetails:[{formType:'DATEPICKER',title:'DATEPICKER1'}]}
        ],
      },
    };
  },
  created() {
    //检查是否编辑
    let advancedFormId = this.$route.query.id;
    if (advancedFormId) {
      console.log(advancedFormId);
      this.form.advancedFormId = advancedFormId
      this.type = "edit";
      this.getDetail(advancedFormId);
    }
  },
  mounted(){},
  methods: {
    //请求高级表单详情
    getDetail(id) {
      this.loading = true;
      let data = {
        advancedFormId: id,
      };
      queryAdvancedFormById(data)
        .then((res) => {
          this.loading = false;
          this.form = res.data;
        })
        .catch((err) => {
          this.loading = false;
          this.tableData = [];
          console.log(err);
        });
    },
    deleteCompoents(detailRequests, index) {
      detailRequests.splice(index, 1);
    },
    addSingle(singleArray) {
      if (singleArray && singleArray.length < 6) {
        singleArray.push({ formType: "SINGLE", title: "", content: "" });
      } else {
        //最多添加6个
      }
    },
    deleteSingle(singleArray, index) {
      singleArray.splice(index, 1);
    },
    deleteMultiple(multipleArray, index) {
      multipleArray.splice(index, 1);
    },
    addMultiple(multipleArray) {
      if (multipleArray && multipleArray.length < 6) {
        multipleArray.push({ formType: "MULTIPLE", title: "", content: "" });
      } else {
        //最多添加6个
      }
    },
    addDropDown(dropDownArray, type) {
      if (dropDownArray && dropDownArray.length < 6) {
        dropDownArray.push({ formType: type, title: "", content: "" });
      } else {
        //最多添加6个
      }
    },
    deleteDropDown(dropDownArray, index) {
      dropDownArray.splice(index, 1);
    },
    //保存表单
    saveForm() {
      this.form.detailRequests.forEach((item, index) => {
        item.serialNumber = index + 1;
        if (item.childrenDetails) {
          item.childrenDetails.forEach((child, ind) => {
            child.serialNumber = ind + 1;
          });
        }
        if (item.formType == "IMAGE") {
          item.childrenDetails.forEach((child, ind) => {
            child.serialNumber = ind + 1;
            //图片单独处理

            child.imgNum = child.title;
          });
        }
      });
      if ((this.type == "edit")) {
        this.form.isDraft = false;
        //编辑
        updateAdvancedForm(this.form).then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          //去列表
          this.$router.go(-1)
        });
      } else {
        //新增
        addAdvancedForm(this.form).then((res) => {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.$router.go(-1)
        });
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
    selectEnter() {
      if (this.checkList.length + this.form.detailRequests.length > 10) {
        this.$message({
          type: "warning",
          message: "最多可添加10个类型",
        });
        return;
      }
      if (this.checkList && this.checkList.length > 0) {
        this.checkList.forEach((item) => {
          this.form.detailRequests.push({
            formType: item,
            title: "",
            content: "",
            childrenDetails: [{ formType: item, title: "", content: "" }],
          });
        });
      }
      this.dialogVisible = false;
    },
    upTop(item, index) {
      if (index == 0) {
        this.$message({
          type: "warning",
          message: "已经到顶",
        });
        return;
      } else {
        const oldDetail = this.form.detailRequests;
        const previous = oldDetail[index - 1];
        oldDetail[index - 1] = item;
        oldDetail[index] = previous;
        this.form.detailRequests = [];
        this.form.detailRequests = oldDetail;
      }
    },
    downBottom(item, index) {
      if (index == 9) {
        this.$message({
          type: "warning",
          message: "已经到顶",
        });
        return;
      } else {
        const oldDetail = this.form.detailRequests;
        const previous = oldDetail[index + 1];
        oldDetail[index + 1] = item;
        oldDetail[index] = previous;
        this.form.detailRequests = [];
        this.form.detailRequests = oldDetail;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
.commentForm {
  border: 1px solid #dcdfe6;
  padding-top: 10px;
  margin-top: 10px;
}
</style>